/* global google */
import React, { Component } from "react";

class ZipCodePrioritizedAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: "", predictions: [], activeIndex: -1 };
  }

  componentDidMount() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { predictions, activeIndex } = this.state;
    if (e.keyCode === 38 && activeIndex > 0) {
      // Up arrow
      this.setState({ activeIndex: activeIndex - 1 });
    } else if (e.keyCode === 40 && activeIndex < predictions.length - 1) {
      // Down arrow
      this.setState({ activeIndex: activeIndex + 1 });
    } else if (e.keyCode === 13 && activeIndex !== -1) {
      // Enter key
      const prediction = predictions[activeIndex];
      this.handlePredictionClick(prediction);
    }
  };

  handleInputChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ searchValue });
    this.props.onChange(e);
    if (!searchValue) {
      this.setState({ predictions: [], activeIndex: -1 });
      return;
    }
    this.autocompleteService.getPlacePredictions(
      {
        input: searchValue,
        types: this.props.types,
        componentRestrictions: { country: "us" },
      },
      (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          this.setState({ predictions: [], activeIndex: -1 });
          return;
        }
        const prioritizedPredictions = this.prioritizeCityStateZip(
          predictions,
          searchValue
        );
        const formattedPredictions = prioritizedPredictions.map((prediction) => {
          const startIndex = prediction.description.toLowerCase().indexOf(searchValue.toLowerCase());
          const endIndex = startIndex + searchValue.length;
          const highlighted = prediction.description.slice(startIndex, endIndex);
          const rest = prediction.description.slice(endIndex);
          return {
            ...prediction,
            formatted: <span>{prediction.description.slice(0, startIndex)}<strong>{highlighted}</strong>{rest}</span>
          };
        });
        this.setState({ predictions: formattedPredictions, activeIndex: -1 });
      }
    );
    document.addEventListener("mousedown", this.handleDocumentClick);
  };
  

  prioritizeCityStateZip(predictions, inputData) {
    const zipCodeRegex = /^\d{5}$/;
    if (zipCodeRegex.test(inputData)) {
      return predictions.sort((a, b) => {
        const aIsPostalCode = a.types.includes("postal_code");
        const bIsPostalCode = b.types.includes("postal_code");
        if (aIsPostalCode && !bIsPostalCode) {
          return -1;
        } else if (!aIsPostalCode && bIsPostalCode) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return predictions;
  }

  handlePredictionClick = (prediction) => {
    const placeId = prediction.place_id;
    const service = new google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const { formatted_address } = place;
        this.props.onPlaceSelected(place);
        this.setState({ searchValue: formatted_address, predictions: [], activeIndex: -1 });
      }
    });
  };

  handlePredictionHover = (index) => {
    this.setState({ activeIndex: index });
  };

  handleDocumentClick = (e) => {
    if (!this.node.contains(e.target)) {
      this.setState({ predictions: [], activeIndex: -1 });
      document.removeEventListener("mousedown", this.handleDocumentClick);
    }
  };
  
  render() {
    const { searchValue, predictions, activeIndex } = this.state;
    return (
      <div ref={(node) => (this.node = node)}>
        <input
          type="text"
          className="searchTerm"
          value={searchValue}
          onChange={this.handleInputChange}
          placeholder="Enter a location"
        />
        {predictions.length > 0 && (
          <div className="pac-container">
            {predictions.map((prediction, index) => (
              <div
                key={prediction.place_id}
                onClick={() => this.handlePredictionClick(prediction)}
                className={`pac-item ${activeIndex === index ? "active" : ""}`}
                onMouseOver={() => this.handlePredictionHover(index)}
              >
                {prediction.formatted}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default ZipCodePrioritizedAutocomplete;